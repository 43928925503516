
.dataTables_filter, .dt-layout-cell {
    padding: 20px 15px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.dataTables_filter label, .dt-layout-cell label {
    font-weight: 400;
}
.dt-layout-cell select {
    margin: 0 8px;
}

.dt-input{
    border: 1px solid #cac6c6;
    height: 35px;
    padding: 7px 15px;
    font-size: 13px;
    border-radius: 2px;
    line-height: 100%;
    background-color: #fff;
	outline:none;
}

.dt-length .dt-input{
    padding: 5px;
}

.dt-input:focus{
    border: 1px solid #df4646;
}

.dt-search label{
    display: none;
}

 .dt-layout-cell .dt-search .dt-input {
    width: 100%;
}

.dt-paging .dt-paging-button.current, .dt-paging-button.current:focus, .dt-paging-button.current:hover {
    background: #df4646d4 !important;
    color: #ffffff !important;

    padding: 5px 13px !important;
    border: none !important;
}

.dataTable thead th{
    padding: 20px 0px 15px 0;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid rgba(168, 168, 168, 0.555);
    color: #252525;
}

.dataTable tbody td {
    padding: 15px 10px 15px 0;
    font-size: 14px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.555);
}

.dataTable tbody tr:hover {
    cursor: pointer;
    background-color: #e4e4e488;
}

.dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate{
	padding-top:0px;
}

#data-table-basic_filter label, #data-table-basic_length label{
	margin-bottom: 0px;
}

.dt-layout-row{
    display: flex;
    justify-content: space-between;
}

.dt-layout-table{
    display: block !important;
    margin:30px 6px;
}

.dt-paging-button.disabled.previous,.dt-paging-button.disabled.next,.dt-paging-button.next, .dt-paging-button.previous{
    color: #ffffff !important;
    border: 1px solid transparent;
    background: transparent;
    padding: 13px 30px 13px 0;
    content:'';
    position: relative;
}

.dt-paging-button.disabled.previous,.dt-paging-button.previous{
    margin-right: 60px;
}

.dt-paging-button.disabled.next{
    padding: 12px 10px;
}

.dt-paging-button.disabled.previous::before,.dt-paging-button.previous::before{
    /* content: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22 fill%3D%22none%22 stroke%3D%22gray%22 stroke-width%3D%222%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22 class%3D%22lucide lucide-chevron-left%22%3E%3Cpath d%3D%22m15 18-6-6 6-6%22/%3E%3C/svg%3E')!important; */
    content:'Previous' ;
    color: #505050;
    position: absolute;
    top:8px;
    cursor: default;
    border: 1px solid #949494;
    padding: 5px 10px;
}

.dt-paging-button.previous::before{
    color: #0a0a0a;
    cursor: pointer;
}



.dt-paging-button.disabled.next::before,.dt-paging-button.next::before{
    /* content: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22 fill%3D%22none%22 stroke%3D%22black%22 stroke-width%3D%222%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22 class%3D%22lucide lucide-chevron-left%22%3E%3Cpath d%3D%22m15 18-6-6 6-6%22/%3E%3C/svg%3E')!important;
     */
    content:'Next' ;
    color: #000;
    padding: 5px 10px;
    /* rotate: 180deg; */
    border: 1px solid #949494;
    position: absolute;
    top:8px;
}

.dt-paging-button.disabled.next::before{
    color: #505050;
    cursor: default;
}

.dt-paging-button.disabled.first,.dt-paging-button.disabled.last{
    display: none;
}


.table-responsive{
    box-shadow: 0 0 20px rgba(202, 201, 201, 0.527);
    border-radius: 5px;
    padding: 30px 10px;
}


.ck-editor__editable {
    min-height: 150px;
}

.dt-empty{
    text-align: center;
}

.dt-paging-button{
    padding:5px 13px;
    margin:0 4px;
}

.dt-paging-button:hover{
    background: #df4646d4;
    color: #fff;
    /* border-radius: 50%; */
}

.dt-paging-button.next{
    padding: 12px 10px;
}

.dt-paging-button.next:hover,.dt-paging-button.previous:hover{
    background: transparent;
}

.dt-layout-row .dt-layout-cell.dt-layout-end .dt-paging {
    width:250px;
    max-width: 350px;
}

.dt-length label{
    color: #ffffff;
    content:'';
    position: relative;
    font-size: 0; 
}


.dt-length label::before{
    content: 'Entries: ';
    color: #050505;
    font-size: 14px; 
}

.dt-info{
    font-size: 14px;  
}

.css-t3ipsp-control{
	border-color: #df4d52 !important;
	box-shadow: 0 0 0 1px #df4d52 !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: 1px solid #df4d52 !important;
    box-shadow: #df4d52, 0 0;
    outline: none;
}


.ck-content ul {
    list-style-type: disc; 
    padding-left: 1.5em;
}
  
.ck-content ol {
    list-style-type: decimal;
    padding-left: 1.5em;
}
  
.ck-content li {
    margin-bottom: 0.5em;
}